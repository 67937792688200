.speaker-block-three .image-box .image {
    border-radius: 4px!important;
    border: none !important;
    height: 250px !important;
  }
  
  .speaker-block-three .info-box:before {
      right: 0 !important;
      left: auto !important;
  }
  
  .speaker-block-three .info-box {
      padding-right: none !important;
      padding: 10px 30px !important;
      width: 85%;
      border-radius: 4px;
  }
  .image-box .image {
      width: 85%!important;
  }
  .MuiPagination-text{
      justify-content: center!important;
  }
  .activee {
      color: #f20487 !important;
  }
  .view-pro{
          background-color: #ffffff;
              color: #29335c
  }
  .spinner-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80vh;
      /* Adjust the height as needed */
  }
  .cus_name {
      word-wrap: break-word;
  }