/* @import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap"); */

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	/* font-family: "Titillium Web", sans-serif; */
}

.zindex{
	z-index: 99;
}
:root {
	--mainColor: #29335c;
	--mainColorLight: #5767aa;
	--secondaryColor: #db2b39;
	--textColor: #eee;
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 1rem;
	background-color: white;
	color: #29335c;
	border-bottom: #29335c 1px solid;
	position: sticky;
	top: 0;
	z-index: 99;
}
.top-nav{
    height: 35px;
    width: 100%;
    background-color:  #29335c;
    color: white;
	font-size: 14px;

}
.navbar-logo{
	height: 65px;
}
nav a {
	/* margin: 0 1rem; */
	color: #29335c;
	text-decoration: none;
    /* font-size: 20px; */
}

nav a:hover {
	color: var(--secondaryColor);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: #29335c;
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}
.top-text{
	font-size: 12px;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: 0;
		left: -100vw;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
	}

	header .responsive_nav {
		transform: translateX(100vw);
		justify-content: center !important;
	}

	.res_nav {
		justify-content: center !important;
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
		color: white;
	}

	nav a {
		font-size: 1.5rem;
		color: white!important;
	}
}

@media only screen and (max-width: 768px) {
	.top-text{
        font-size: 10px;
    }
}
@media only screen and (max-width: 488px) {

	.navbar-logo{
		height: 50px;
	}

	.top-nav{
		height: 55px;
	}

}