.features .true {
    font-size: 12px;
}

.features {
    text-align: justify;
}
.price {
    font-size: 20px !important;
}
.card-plan{
    background-color: white;
    border-radius: 10px;
}
.Pay-image {
    height: 600px;
}
@media only screen and (max-width: 430px) {
    .Pay-image {
        height: 450px;
    }

    .mbl-pad {
        padding: 0.5rem !important;
    }
 }