.j_dd{
    border: 0 solid;
    border-color: black;
    padding: 30px !important;
    border-width: 1px!important;
}
.jd_text{
    color: #697a8d;
}
.back-btn{
        margin-left: 30px;
        margin-top: 20px;
    
}
.border-line{
    border: black;
    border: 0 solid;
    border-width: 1px!important;
}
.gen_print{
    border-color: black !important;
    padding: 0 10px;
    margin: 10px 6px !important;
    
}
.page-item .page-link {
    border-radius: 0.25rem;
    border: 1px solid #f30863 ;
    color: black;
}
.page-item .page-link:focus {
    border-radius: 0.25rem;
    background-color: #f30863 ;
    color: white;
}
.load-spin {
    width: 130px;
    height: 130px;
    border-radius: 50%;
}

.back-spin {
    background-image: url("./back-spin.gif");
    background-repeat: no-repeat;
    background-position: center;
}

@media only screen and (max-width: 768px) {
    .j_dd{
        padding: 10px !important;
    }
}