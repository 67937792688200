/* @media only screen and (max-width: 1199px) {
.card{
   width: 16rem;
}
}


@media only screen and (max-width: 480px) {
        .card{
           width: 10rem;
        }
        } */

.slick-arrow {
    display: none !important;
}

.info-box:before {
   right: 0 !important;
   left: auto !important;
}

.info-box {
   padding-right: none !important;
   padding: 10px 30px !important;
   width: 85%;
   border-radius: 4px;
}

.image-box .image {
   width: 85% !important;
}

.user-img {
   height: 325px!important;
}
.info-box-div {
   background-color: #f20487 !important;
   border-radius: 10px;
      width: 100% !important;
         margin: auto;
            margin-bottom: 10px;
}
.info-box {
   background-color: #f20487;
   
}
.name {
   color: white;
}
.designation{
   color: white;
}
.card-text {
   color: white;
}
.pro-border{
   border-radius: 10px 0 0 10px !important;
   padding: 10px !important;
}
.load-spin {
   width: 130px;
   height: 130px;
   border-radius: 50%;
}

.back-spin {
   background-image: url("../back-spin.gif");
   background-repeat: no-repeat;
   background-position: center;
}

.view-pro {
   background-color: #ffffff;
   color: #29335c
}
.card-title{
   margin-bottom: 0px !important;
}

.spinner-container {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 80vh;
   /* Adjust the height as needed */
}
.card-img-top{
   height: 300px!important;
   width: 100%;
   margin: auto!important;
   margin-top: 10px !important;
}
@media only screen and (max-width: 768px) {
   .card {
      width: 13rem;
   }
}
@media only screen and (max-width: 768px) {
   .card-register {
       width: 100%!important;
   }
}